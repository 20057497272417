/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import PageBuilder from "~sanity/PageBuilder.jsx";
import { Layout, SEO } from "~components";

/** ============================================================================
 * @component
 * Core Page.jsx template.
 */
const Page = ({ data, location }) => {
  // ---------------------------------------------------------------------------
  // variables

  const cms = data.sanityPage;

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        {cms?.pagebuilder?.sections?.[0] && (
          <PageBuilder pagebuilder={cms.pagebuilder} />
        )}
      </Layout>
    </>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      seoDescription
      seoKeywords

      ...PageBuilderFragment
    }
  }
`;
